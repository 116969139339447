.logo-image {
  width: 40px;
}

.header {
  background-color: #717590 !important;
}

.user-dropdown {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "Roboto";
  font-size: 16px;
}

@media (max-width: 600px) {
  .RaAppBar-menuButton {
    display: none !important;
  }

  .RaAppBar-toolbar {
    width: 100% !important;
  }

  .mobile {
    display: none;
  }

  .logo {
    padding-left: 16px;
  }

  .user-dropdown span {
    display: none;
  }
}
