.wrapper-send {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 12px;
}

.upload-file {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
}

.MuiButtonBase-root.button-primary {
  text-transform: uppercase;
  padding: 9px 16px;
  background-color: #4884ef !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 14px !important;
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  display: flex !important;
  gap: 8px !important;
  margin: 0;
}

.MuiButtonBase-root.button-primary span {
  margin: 0;
  display: flex !important;
  gap: 8px !important;
  font-weight: 500 !important;
}

.textarea-block {
  position: relative;
}

.textarea {
  width: calc(100% - 62px);
  padding: 8px 52px 8px 10px;
  margin-right: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 4px;
  height: 40px;
  min-height: 60px;
  font-family: "Roboto";
  resize: none;
}

.voice-recorder-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.voice-recorder {

}

.voice-recorder-button img {
  width: 24px;
  height: 24px;
}

.voice-recorder__audio {
  display: flex;
  align-items: center;
}

.voice-recorder__audio .voice-recorder-button.recording {
  margin-left: 10px;
}

.send-button-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 11px;
  width: 42px;
  min-width: 42px;
}

.MuiButtonBase-root.voice-recorder-button,
.MuiButtonBase-root.button-send {
  padding: 9px;
  width: 42px;
  min-width: 42px;
}

.MuiButtonBase-root.voice-recorder-button.recording {
  background-color: red;
}

.MuiButtonBase-root.voice-recorder-button span,
.MuiButtonBase-root.button-send span {
  margin: 0;
  display: flex;
  gap: 8px;
}

.MuiButtonBase-root.button-text-edit {
  color: #007dff;
}

.MuiButtonBase-root.button-text-edit span {
  display: flex !important;
  gap: 8px !important;
}

.MuiButtonBase-root.button-text-delete {
  color: #ff505f;
}

.MuiButtonBase-root.button-text-delete span {
  display: flex !important;
  gap: 8px !important;
}

.file-name {
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}

.chat-header {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
  color: #000000;
  font-weight: 500;
  margin-bottom: 16px;
}

.chat-header-left {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.chat-header-right {
  display: flex;
  gap: 8px;
  align-items: center;
}

.edit-pet {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.edit-pet input {
  padding: 8px 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 4px;
  height: 24px;
  font-family: "Roboto";
  width: 200px;
}

.edit-pet .actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.edit-pet .actions span {
  color: #007dff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.back-button {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #007dff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 8px;
}

.view-pet {
  display: flex;
  gap: 8px;
  align-items: center;
}

.view-pet img {
  cursor: pointer;
}

.view-pet span {
  white-space: nowrap;
}

.view-pet .placeholder {
  opacity: 0.5;
}

@media (max-width: 600px) {
  .title {
    font-size: 20px;
  }

  .upload-file {
    justify-content: space-between;
  }

  .MuiButtonBase-root.button-text-delete:hover {
    color: #ff505f;
    background-color: transparent;
  }

  .MuiButtonBase-root.button-send {
    background-color: #4884ef !important;
    border-radius: 4px !important;
    color: #fff !important;
  }

  .MuiButtonBase-root.button-text-delete {
    font-size: 14px !important;
    height: 38px;
  }

  .MuiButtonBase-root.button-text-edit {
    font-size: 14px !important;
    height: 38px;
  }
}
