.clients .MuiTypography-root,
.clients .MuiTableCell-root {
  font-weight: inherit;
}

.MuiTabs-flexContainer {
  overflow-x: auto;
}

.MuiTabs-root {
  max-width: calc(100vw - 24px);
}

.end-chat-button {
  display: flex;
  justify-content: flex-end;
}
