.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 1px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: radial-gradient(
    circle at 50% 14em,
    rgb(49, 50, 100) 0%,
    rgb(0, 2, 59) 60%,
    rgb(0, 2, 59) 100%
  );
}

.form {
  width: 300px;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.inputItem {
  display: flex;
  gap: 4px;
  flex-direction: column;

  label {
    font-size: 14px;
  }
}

.input {
  height: 22px;
  min-height: 38px;
  border-radius: 4px;
  border: 1px solid gray;
  padding: 0px 14px;
}

.loginButton {
  width: 100%;
  text-align: center;
  height: 40px;
  font-size: 14px !important;
  padding: 6px 16px !important;
  background-color: #4884ef !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-family: "Roboto" !important;

  span {
    margin: 0;
  }
}
